<template>
    <CoreContainer :aria-label="$t('accessibility.advantages')">
        <BaseContainerContent class="grid grid-cols-2 gap-5 gap-x-1.5 md:grid-cols-4 md:gap-x-5">
            <template v-for="item in advantages" :key="item.id">
                <CardIconCircle
                    v-if="item.id === 'free-shipping' ? isSetFreeShipping : true"
                    :title="item.label"
                    :color="item.bgColor"
                    class="mon-advantage"
                    size="lg"
                >
                    <component
                        :is="item.icon"
                        class="mon-advantage__icon"
                        :class="`mon-advantage__icon--${item.id}`"
                        aria-hidden
                    />

                    <template #title>
                        <div class="mon-advantage__text">
                            {{ item.label }}
                        </div>
                    </template>
                </CardIconCircle>
            </template>
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>

import { IconGift, IconShipping, IconCircularArrow, IconPin } from '#components'
import type { ButtonColors } from '../../components/base/BaseUiButton.vue'
import { getPriceValue } from '@sim-api-utils/localization'

const { t } = useI18n()
const { freeShippingPrice } = useProperties()

export interface AdvantageItem {
    id: string
    label: string
    icon: unknown
    bgColor: ButtonColors
}

const isSetFreeShipping = computed<boolean>(() => !!getPriceValue(freeShippingPrice.value))

const advantages = computed<AdvantageItem[]>(() => [
    {
        id: 'gift',
        label: t('advantages.free_gift'),
        icon: IconGift,
        bgColor: 'success',
    },
    {
        id: 'return',
        label: t('advantages.exchange_product'),
        icon: IconCircularArrow,
        bgColor: 'danger',
    },
    {
        id: 'free-shipping',
        label: t('advantages.free_shipping'),   // TODO: use real free shipping price in the label
        icon: IconShipping,
        bgColor: 'warning',
    },
    {
        id: 'salons',
        label: t('advantages.salons_delivery'),
        icon: IconPin,
        bgColor: 'primary',
    },
])

</script>

<style lang="scss" scoped>

.mon-advantage__icon--gift {
    width: 20.24px;
    height: 19.23px;

    @include more-than(lg) {
        width: 26.32px;
        height: 25px;
    }
}

.mon-advantage__icon--free-shipping {
    width: 23.27px;
    height: 16.92px;

    @include more-than(lg) {
        width: 30.25px;
        height: 22px;
    }
}

.mon-advantage__icon--return {
    width: 20.77px;
    height: 20.77px;

    @include more-than(lg) {
        width: 27px;
        height: 27px;
    }
}

.mon-advantage__icon--salons {
    width: 13.36px;
    height: 18.46px;

    @include more-than(lg) {
        width: 17.37px;
        height: 24px;
    }
}

.mon-advantage__text {
    @include mon-text-small;

    @include more-than(lg) {
        @include mon-text-base;
    }
}

</style>
